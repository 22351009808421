<mat-dialog-content *ngIf="usertype=='Installer'">
    <div style="text-align: right;">
      <mat-icon matTooltip="Edit Now" style="cursor: pointer;" (click)="addDialog()">create</mat-icon>&nbsp;&nbsp;
        <mat-icon matTooltip="Close" style="cursor: pointer;" (click)="close()">close</mat-icon>
      </div>
    <div>
        <div>
          <div style="width: 400px;padding:15px;">
            <div style="background-color: black;">
                <div>
                    <img style="display: block;margin:auto;width: 250px;padding:50px" src="assets/images/C-logo.png" />
                </div>
                
              <!-- <div class="user-name">{{data.firstName}} {{data.lastName}}</div>
              <div class="name-center" style="text-align: center;">Installer</div> -->
            </div>
            <img *ngIf="imageshow==null" src="https://iotac.eu/wp-content/plugins/ultimate-member/assets/img/default_avatar.jpg" class="user-img" alt="">
            <img *ngIf="imageshow!=null" [src]="src" style="height:100px;width:100px;border-radius:50%;margin-top: -53px;margin-left: 5px;" alt="">
            <mat-icon style="margin-left: -15px;color: grey;background-color: white;cursor: pointer;" (click)="addImage()">photo_camera</mat-icon>
            <h5>{{data.firstName}} {{data.lastName}}</h5>
            <p>
              {{data.companyName}}
            </p>
            <div class="row">
              <div class="col-4" style="text-align: center;">
                <mat-icon>call</mat-icon><br/>
                <small>(+1) {{data.phoneNumber}}</small>
              </div>
              <div class="col-4" style="text-align: center;">
                &nbsp;&nbsp;&nbsp;<mat-icon>mail</mat-icon><br/>
                <small>{{data.email}}</small>
              </div>
              <div class="col-4" style="text-align: center;">
                <mat-icon>public</mat-icon><br/>
                <small>{{data.country}}</small>
              </div>
            </div>
          </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="usertype=='SuperAdministrator'">
  <div style="text-align: right;">
    <mat-icon matTooltip="Edit Now" style="cursor: pointer;" (click)="addDialog1()">create</mat-icon>&nbsp;&nbsp;
      <mat-icon matTooltip="Close" style="cursor: pointer;" (click)="close()">close</mat-icon>
    </div>
  <div>
      <div>
        <div style="width: 400px;padding:15px;">
          <div style="background-color: black;">
              <div>
                  <img style="display: block;margin:auto;width: 250px;padding:50px" src="assets/images/C-logo.png" />
              </div>
              
            <!-- <div class="user-name">{{data.firstName}} {{data.lastName}}</div>
            <div class="name-center" style="text-align: center;">Installer</div> -->
          </div>
          <img *ngIf="imageshow==null" src="https://iotac.eu/wp-content/plugins/ultimate-member/assets/img/default_avatar.jpg" class="user-img" alt="">
          <img *ngIf="imageshow!=null" [src]="src" style="height:100px;width:100px;border-radius:50%;margin-top: -53px;margin-left: 5px;" alt="">
          <mat-icon style="margin-left: -15px;color: grey;background-color: white;cursor: pointer;" (click)="addImage1()">photo_camera</mat-icon>
          <h5>{{data.name}}</h5>
          <p>
            {{data.address}}
          </p>
          <div class="row">
            <div class="col-4" style="text-align: center;">
              <mat-icon>call</mat-icon><br/>
              <small>(+1) {{data.phoneNumber}}</small>
            </div>
            <div class="col-4" style="text-align: center;">
              &nbsp;&nbsp;&nbsp;<mat-icon>mail</mat-icon><br/>
              <small>{{data.email}}</small>
            </div>
            <div class="col-4" style="text-align: center;">
              <mat-icon>person</mat-icon><br/>
              <small>{{data.userType}}</small>
            </div>
          </div>
        </div>
      </div>
  </div>
</mat-dialog-content>
