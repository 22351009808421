import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/service/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss']
})
export class EditprofileComponent implements OnInit {

  dataForm: FormGroup;
  loading = false;
  hide = true;
  public userId: string ='';
  src = null;
  imageshow = null;
  fileUpload = {status: '', message: '', filePath: ''};
  profileForm: FormGroup;
  message = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userId = sessionStorage.getItem("userId");
    this.message = sessionStorage.getItem("message");

    this.dataForm = new FormGroup({
      name: new FormControl({value: '', disabled: false}, Validators.required),
      phoneNumber: new FormControl({value: '', disabled: false}, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      address: new FormControl({value: '', disabled: false}, Validators.required),
    });

    this.profileForm = new FormGroup({
      profile: new FormControl({value: '', disabled: false}, Validators.required),
    });

    this.authService.getUser(this.userId).subscribe((response) => {
      let res = response.author;

      this.dataForm.patchValue({
        name: res.name == null?'': res.name,
        phoneNumber: res.phoneNumber == null?'': res.phoneNumber,
        address: res.address == null?'': res.address,
      });

      this.imageshow = res.src;
      this.src = environment.apiUrl + "auth/files/" + res.src;
    })

  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
    }
  }

  onUpload() {
    this.loading = true;
    const formData = new FormData();
    formData.append('profile', this.profileForm.get('profile').value);
  
    this.authService.profileUpload1(this.userId, formData).subscribe( res => {
            this.fileUpload = res;
  
            if(this.fileUpload.message == '100') {
              this.toastr.success('Successfully Updated');
              window.location.reload();
              this.loading = false;
              this.dialog.closeAll();
            }
        }
    );
  }

  onSubmit() {
    this.loading = true;
    //this.spinnerButtonOptions.active = true;
    let data = {
      name: this.dataForm.value.name,
      phoneNumber: this.dataForm.value.phoneNumber,
      address: this.dataForm.value.address,
    }
    console.log(data);
    
    this.authService.updateUser(this.userId, data).subscribe((response) =>{
      this.toastr.success('Successfully Updated');
      window.location.reload();
      this.loading = false;
      this.dialog.closeAll();
    },(err) =>{
      this.toastr.error('Mobile no. or email id is already exist!');
      this.loading = false;
    });
  }

  close() {
    this.dialog.closeAll();
  };

}
