import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/service/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-editinstaller',
  templateUrl: './editinstaller.component.html',
  styleUrls: ['./editinstaller.component.scss']
})
export class EditinstallerComponent implements OnInit {

  dataForm: FormGroup;
  loading = false;
  hide = true;
  public installerId: string ='';
  src = null;
  imageshow = null;
  fileUpload = {status: '', message: '', filePath: ''};
  profileForm: FormGroup;
  message = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.installerId = sessionStorage.getItem("installerId");
    this.message = sessionStorage.getItem("message");

    this.dataForm = new FormGroup({
      firstName: new FormControl({value: '', disabled: false}, Validators.required),
      lastName: new FormControl({value: '', disabled: false}, Validators.required),
      email: new FormControl({value: '', disabled: false}, [Validators.required, Validators.email]),
      phoneNumber: new FormControl({value: '', disabled: false}, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      companyName: new FormControl({value: '', disabled: false}, Validators.required),
      country: new FormControl({value: '', disabled: false}, Validators.required),
    });

    this.profileForm = new FormGroup({
      profile: new FormControl({value: '', disabled: false}, Validators.required),
    });

    this.authService.getInstaller(this.installerId).subscribe((response) => {
      let res = response.author;

      this.dataForm.patchValue({
        firstName: res.firstName == null?'': res.firstName,
        lastName: res.lastName == null?'': res.lastName,
        email: res.email == null?'': res.email,
        phoneNumber: res.phoneNumber == null?'': res.phoneNumber,
        companyName: res.companyName == null?'': res.companyName,
        country: res.country == null?'': res.country,
      });

      this.imageshow = res.src;
      this.src = environment.apiUrl + "installer/files/" + res.src;
    })

  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
    }
  }

  onUpload() {
    this.loading = true;
    const formData = new FormData();
    formData.append('profile', this.profileForm.get('profile').value);
  
    this.authService.profileUpload(this.installerId, formData).subscribe( res => {
            this.fileUpload = res;
  
            if(this.fileUpload.message == '100') {
              this.toastr.success('Successfully Updated');
              window.location.reload();
              this.loading = false;
              this.dialog.closeAll();
            }
        }
    );
  }

  onSubmit() {
    this.loading = true;
    //this.spinnerButtonOptions.active = true;
    let data = {
      firstName: this.dataForm.value.firstName,
      lastName: this.dataForm.value.lastName,
      email: this.dataForm.value.email,
      phoneNumber: this.dataForm.value.phoneNumber,
      companyName: this.dataForm.value.companyName,
      country: this.dataForm.value.country,
    }
    console.log(data);
    
    this.authService.updateInstaller(this.installerId, data).subscribe((response) =>{
      this.toastr.success('Successfully Updated');
      window.location.reload();
      this.loading = false;
      this.dialog.closeAll();
    },(err) =>{
      this.toastr.error('Mobile no. or email id is already exist!');
      this.loading = false;
    });
  }

  close() {
    this.dialog.closeAll();
  };

}
