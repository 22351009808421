import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/core/service/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { EditinstallerComponent } from '../../admin/installers/editinstaller/editinstaller.component';
import { EditprofileComponent } from '../../admin/editprofile/editprofile.component';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {

  public data: any;
  public installerId: string ='';
  public userId: string ='';
  public usertype: string ='';
  src = null;
  imageshow = null;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.installerId = localStorage.getItem("INSTALLERID");
    this.usertype = localStorage.getItem("USERTYPE");
    this.userId = localStorage.getItem("USERID");

    if(this.usertype=='Installer') {
      this.authService.getInstaller(this.installerId).subscribe((response) =>{
        this.data = response.author;
        this.imageshow = this.data.src;
        this.src = environment.apiUrl + "installer/files/" + this.data.src;
      })
    }
    else {
      this.authService.getUser(this.userId).subscribe((response) =>{
        this.data = response.author;
        this.imageshow = this.data.src;
        this.src = environment.apiUrl + "auth/files/" + this.data.src;
      })
    }
  }

  close() {
    this.dialog.closeAll();
  };

  addDialog() {
    this.dialog.closeAll();
    sessionStorage.setItem("message", 'Update');
    sessionStorage.setItem("installerId", this.installerId);
    this.dialog.open(EditinstallerComponent);
  }

  addDialog1() {
    this.dialog.closeAll();
    sessionStorage.setItem("message", 'Update');
    sessionStorage.setItem("userId", this.userId);
    this.dialog.open(EditprofileComponent);
  }

  addImage() {
    this.dialog.closeAll();
    sessionStorage.setItem("message", 'Upload');
    sessionStorage.setItem("installerId", this.installerId);
    this.dialog.open(EditinstallerComponent);
  }

  addImage1() {
    this.dialog.closeAll();
    sessionStorage.setItem("message", 'Upload');
    sessionStorage.setItem("userId", this.userId);
    this.dialog.open(EditprofileComponent);
  }

}
