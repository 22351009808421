<div class="row">
    <div class="col-10">
      <h2 mat-dialog-title>Edit Profile</h2>
    </div>
    <div class="col-2" style="text-align: right;">
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </div>
  </div>
<mat-dialog-content>
  <a [href]="src" target="_blank">
    <img *ngIf="imageshow!=null" [src]="src" style="display: block;margin:auto;height: 100px;width: 100px;border-radius: 50%;" />
  </a>
    <form *ngIf="message=='Update'" class="m-4" [formGroup]="dataForm" (ngSubmit)="onSubmit()" style="width: 450px;">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-form-field class="example-full-width">
              <mat-label>Full Name</mat-label>
              <input matInput ngDefaultControl formControlName="name" required>
              <mat-error *ngIf="dataForm.get('name').hasError('required')">
                Full Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <span matPrefix>(+1) &nbsp;</span>
              <input matInput formControlName="phoneNumber" required>
              <mat-error *ngIf="dataForm.get('phoneNumber').hasError('required') || dataForm.get('phoneNumber').touched">
                Please enter a valid Mobile number
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-form-field class="example-full-width">
              <mat-label>Address</mat-label>
              <input matInput ngDefaultControl formControlName="address" required>
              <mat-error *ngIf="dataForm.get('address').hasError('required')">
                Address is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" style="text-align: right;">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button mat-raised-button color="primary" [class.auth-spinner]="loading" [disabled]="loading"
                  class="auth-form-btn btn-primary" [disabled]="!dataForm.valid" type="submit">Update</button>
          </div>
        </div>
    </form>

    <form class="m-4" [formGroup]="profileForm" (ngSubmit)="onUpload()" style="width: 450px;">
      <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
              <input type="file" name="file" (change)="onSelectedFile($event)" />
            </div>
      </div>
      <div class="row" style="text-align: right;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
          <button mat-raised-button color="primary" [class.auth-spinner]="loading" [disabled]="loading"
                class="auth-form-btn btn-primary" [disabled]="!profileForm.valid" type="submit">Upload</button>
            <!-- <mat-spinner-button [disabled]="!dataForm.valid" [options]="spinnerButtonOptions">
            </mat-spinner-button> -->
            <!-- <mat-bar-button [options]="spinnerButtonOptions" ></mat-bar-button> -->
        </div>
      </div>
  </form>
</mat-dialog-content>